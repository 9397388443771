<template>
    <div class="text-container project-scroll">
        <div class="text-container_content">
            <h1 v-html="$content(title)"></h1>
            <p :style="{'font-size': size ? size : ''}" v-html="$content(text)"></p>
        </div>

        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "TextContainer",
    props: ['title', 'text', 'size']
}
</script>

<style lang="scss" scoped>
    .text-container {
        height: 100%;
        width: 100%;
        overflow-y: auto;
        padding-right: 25px;

        &_content {

            & h1 {
                font-family: Montserrat-SemiBold, sans-serif;
                font-size: 22px !important;
                //color: var(--main-titles);
                color: var(--main-result-success) !important;
                margin-bottom: 35px;
                text-align: center;

              margin-bottom: 30px !important;
              text-align: start  !important;
              width: 492px;
            }

            & p {
                font-family: Montserrat-Regular, sans-serif;
                font-size: 14px;
                color: var(--main-dark-paper);

                font-family: Montserrat-Medium, sans-serif;
                font-size: 16px;
                color: #595959;

                ul {
                  padding-left: 20px;
                }
            }
        }
    }
</style>