<template>
    <div class="terms-modal modal-bordered">
        <TextContainer title="terms_modal_title" text="terms_modal_text"></TextContainer>
    </div>
</template>

<script>
import TextContainer from "@/components/TextContainer";
export default {
name: "Terms",
    components: {TextContainer}
}
</script>

<style lang="scss" scoped>
    .terms-modal {
        background: white;
        height: 500px;
        padding: 40px 25px;
    }
</style>